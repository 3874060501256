import { request } from '@/sujs/http'

export function fetchList(url, query) {
	return request({
		url:url,
		method:"POST",
		data:query
	})
}

export function fetchDetail(url, id) {
	return request({
		url:url,
		method:"POST",
		data:{ 'id': id }
	})
}

export function saveData(url, data) {
	return request({
		url:url,
		method:"POST",
		data:data
	})
}

export function deleteData(url, ids) {
	return request({
		url:url,
		method:"POST",
		data:{ 'ids': ids }
	})
}

export function changeState(url, ids, state) {
	return request({
		url:url,
		method:"POST",
		data:{ 'ids': ids, 'state': state }
	})
}
