<template>
	<div class="cuoti" :style="{direction: yuyan.now=='hanyu'?	'ltr':'rtl'}">
		<data-table  ref="pagingTable" :options="options" :list-query="listQuery">
			<template #filter-content>

				<el-button class="filter-item top_far" style="float: right;margin-bottom: 10px;" type="primary" icon="el-icon-magic-stick"
					@click="startTrain">
					{{yuyan.cuoti_train}}
				</el-button>

			</template>
			<!-- <template slot="data-columns"> -->
			<template #data-columns>
				<el-table-column :label="yuyan.cuoti_timu" show-overflow-tooltip>
					<!-- <template slot-scope="scope"> -->
					<template #default="scope">
						<router-link :to="{ path: 'ViewQu', query:{ id: scope.row.quId}}">
							{{ scope.row.title }}
						</router-link>
					</template>
				</el-table-column>

				<el-table-column :label="yuyan.cuoti_cishu" prop="wrongCount" align="center" />

				<el-table-column :label="yuyan.cuoti_time" align="center" prop="updateTime" />

			</template>

		</data-table>
	</div>
</template>

<script>
	import DataTable from '@/components/DataTable'

	export default {
		name: 'QuList',
		components: {
			DataTable
		},
		data() {
			return {
				yuyan: {},
				listQuery: {
					current: 1,
					size: 10,
					params: {
						title: '',
						examId: ''
					}
				},

				options: {

					// 可批量操作
					multi: true,

					// 批量操作列表
					multiActions: [{
						value: 'delete',
						label: '删除'
					}],
					// 列表请求URL
					listUrl: '/user/wrong-book/paging',
					// 删除请求URL
					deleteUrl: '/user/wrong-book/delete'
				}
			}
		},
		created() {
			this.yuyan = this.$language()
			const id = this.$route.query.examId
			if (typeof id !== 'undefined') {
				this.listQuery.params.examId = id
				// this.fetchData(id)
			}
		},
		watch: {
			'$store.state.language_num'(to, from) {
				this.yuyan = this.$language()
			}
		},
		methods: {

			startTrain() {
	/* 			this.$router.push({
					path: 'training',
					query: {
						id: this.listQuery.params.examId
					}
				}) */
				this.$router.push({
					name: 'BookTraining',
					params: {
						examId: this.listQuery.params.examId
					}
				})
			}

		}
	}
</script>


<style scoped>
	.cuoti {
		margin:0 40px;
	}
	
	.top_far{
		margin-top: 20px;
	}
	
	.neirong {
		margin-top: 20px;
	}
</style>
